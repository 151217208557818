import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { List } from '../../components';
import { DateTime } from 'luxon';
export const Payouts = ({ escrows, claimRelease, component }) => {
    const data = escrows.map(({ status, tx_envelope_xdr, amount, first_name, avatar_url, name, timestamp }) => {
        const dt = DateTime.fromSeconds(timestamp);
        const action = status === 'UNLOCKED' ? { value: { text: 'Claim', onClick: claimRelease, content: { tx_envelope_xdr } }, type: 'button' } : { text: '-', type: 'simple' };
        return [
            { value: { first_name: first_name, avatar_url: avatar_url }, type: 'person' },
            { value: name, type: 'simple' },
            { value: `${dt.weekdayShort} ${dt.day}/${dt.month} ${('0' + dt.hour).slice(-2)}:${('0' + dt.minute).slice(-2)} hs`, type: 'simple' },
            { value: `${amount} xlm`, type: 'simple' },
            action,
        ];
    });
    return (_jsxs(_Fragment, { children: [_jsx("div", { className: "text-subheading-medium mb-[8px] text-neutral-800", children: "Incomming Payouts" }), _jsx(List, { data: data, component: component })] }));
};
