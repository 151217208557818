export const timesOfDay = [
    { time: '12AM', code: '2' },
    { time: '1AM', code: '14' },
    { time: '2AM', code: '26' },
    { time: '3AM', code: '38' },
    { time: '4AM', code: '50' },
    { time: '5AM', code: '62' },
    { time: '6AM', code: '74' },
    { time: '7AM', code: '86' },
    { time: '8AM', code: '98' },
    { time: '9AM', code: '110' },
    { time: '10AM', code: '122' },
    { time: '11AM', code: '134' },
    { time: '12PM', code: '146' },
    { time: '1PM', code: '158' },
    { time: '2PM', code: '170' },
    { time: '3PM', code: '182' },
    { time: '4PM', code: '194' },
    { time: '5PM', code: '206' },
    { time: '6PM', code: '218' },
    { time: '7PM', code: '230' },
    { time: '8PM', code: '242' },
    { time: '9PM', code: '254' },
    { time: '10PM', code: '266' },
    { time: '11PM', code: '278' }
];
