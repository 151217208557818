import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import * as React from 'react';
import { Button, Input } from '../../index';
import { useForm } from '../context';
import clsx from 'clsx';
export const StepperForm = ({ fields, action, debug = false, button, }) => {
    const getFields = fields.map((item) => {
        const isArr = Object.prototype.toString.call(item) == '[object Array]';
        if (isArr) {
            const output = item.map(({ field }) => field);
            return output;
        }
        else {
            const { field, title, description } = item;
            return [{ ...field, title, description }];
        }
    });
    const { fieldsState, updateForm } = useForm();
    const maxStep = fields.length - 1;
    const [step, setStep] = React.useState(1);
    const [refGroup, setRefGroup] = React.useState([]);
    const [validation, setValidation] = React.useState(null);
    console.log('fieldsState', fieldsState);
    // Refactor use a Ref to the Form and get their sibblings.
    React.useEffect(() => {
        const fields = getFields[step];
        if (!!fields) {
            const arr = fields.map((field) => {
                let mRef = undefined;
                if (field.type === 'text') {
                    mRef = React.createRef();
                }
                else if (field.type === 'textarea') {
                    mRef = React.createRef();
                }
                else if (field.type === 'select') {
                    mRef = React.createRef();
                }
                else {
                    mRef = React.createRef();
                }
                return { mRef, id: field.id, type: field.type, validation: field.validation, field: field };
            });
            setRefGroup(arr);
        }
        else if (step === fieldsState.length) {
            console.log('Submiting the form!');
        }
    }, [step]);
    React.useEffect(() => {
        console.log('Checking for validations 123');
        if (refGroup.length > 0) {
            // console.log('refGroup', refGroup)
            const group = refGroup.map((field) => {
                // if (mRef.current?.value.length > 0) return true
                // console.log('ref',mRef.current?.value)
                console.log('field', field);
                return field.validation(field.mRef.current?.value) ? true : false;
            });
            // setFieldStatus(group.every(e => e === true))
        }
    }, [validation]);
    const nextStep = ({ key }) => {
        if (step <= maxStep) {
            const newArr = [...fieldsState];
            const newData = refGroup.map((item) => {
                console.log('ITEM', item, item.mRef.current?.value);
                const { field, type, id, mRef } = item;
                // field.options?.find((e: any) => defaultValue === e.value)
                if (!!field.options?.length) {
                    const { name, value } = field.options?.find((o) => o.value === mRef.current?.value);
                    return { id, value: mRef.current?.value, type, field: { ...field, name, value } };
                }
                return { id, value: mRef.current?.value, type, field };
            });
            newArr[key] = [...newData];
            updateForm([...newArr]);
            setStep(step + 1);
            setValidation(null);
        }
    };
    const getRef = (id) => {
        const getCurrent = refGroup.filter((item) => item.id === id);
        if (!!getCurrent[0]) {
            return getCurrent[0].mRef;
        }
    };
    const goBack = () => {
        if (step >= 2) {
            setStep(step + -1);
        }
    };
    const barContainer = clsx(`grid gap-[4px] grid-cols-${maxStep + 2}`);
    return (_jsx(_Fragment, { children: _jsx("form", { method: "post", action: !!action ? action : '', children: step === fieldsState.length ?
                _jsxs(_Fragment, { children: [_jsx("div", { className: 'text-subheading-bold text-neutral-800 mb-[20px]', children: "Review details" }), _jsx("div", { className: "space-y-[8px]", children: fieldsState.map((fields, key) => {
                                return fields.map(({ id, value, name, type, hidden, field }) => {
                                    if (!!hidden)
                                        return '';
                                    return _jsxs("div", { children: [_jsx("div", { className: "text-neutral-400 text-paragraph-small-medium capitalize", children: field.label }), _jsx("div", { className: "text-neutral-600 text-paragraph-medium-bold max-w-fit truncate", children: type === 'select' ? field.name : value }), _jsx("input", { hidden: true, id: id, name: id, defaultValue: value })] }, key);
                                });
                            }) }), _jsxs("div", { className: "pt-6 flex flex-col space-y-[20px]", children: [_jsxs("div", { className: "flex flex-row justify-start space-x-[8px]", children: [_jsx(Button, { text: button.text, type: "submit", rounded: "medium", variant: button.variant, customCss: button.customCss }), _jsx(Button, { onClick: () => goBack(), text: "back", variant: 'basic' })] }), _jsxs("div", { className: "flex flex-col space-y-[8px]", children: [_jsxs("div", { children: [_jsx("span", { className: "text-green-400 text-paragraph-medium-bold", children: step }), _jsxs("span", { className: "text-green-200 text-paragraph-medium-medium pl-[2px]", children: ["/", maxStep + 1] })] }), _jsx("div", { className: barContainer, children: _jsx("div", { className: `bg-green-400 rounded-[2px] h-[4px] col-span-${step + 1}` }) })] })] })] })
                :
                    _jsx("div", { className: "flex flex-col justify-between h-[420px]", children: getFields.map((fields, key) => {
                            if (step === key) {
                                return _jsxs(_Fragment, { children: [_jsx("div", { className: 'flex flex-col space-y-[12px]', children: fields.map(({ type, id, label, placeholder, autoComplete, required, icon, uploadFile, options, hidden = false, afterChange, validation, maxLength, populateOptions, belongs_to, disabled = false, defaultValue, title, description, multiple }) => {
                                                const mRef = getRef(id);
                                                return _jsxs(_Fragment, { children: [_jsx("div", { className: "text-subheading-bold text-neutral-800", children: title }), _jsx("div", { className: "text-paragraph-small-medium text-neutral-400", children: description }), _jsx(Input, { type: type, id: id, disabled: disabled, label: label, mRef: mRef, options: options, afterChange: type === 'select' || type === 'radiobox' ? afterChange : setValidation, maxLength: maxLength, uploadFile: uploadFile, icon: icon, validation: validation, belongs_to: belongs_to, populateOptions: populateOptions, hidden: hidden, debug: debug, defaultValue: !!defaultValue ? defaultValue : '', autoComplete: autoComplete, placeholder: placeholder, required: required, multiple: multiple }, key)] });
                                            }) }), _jsxs("div", { className: "pt-6 flex flex-col space-y-[20px]", children: [_jsxs("div", { className: "flex flex-row justify-start space-x-[8px]", children: [_jsx(Button, { onClick: () => nextStep({ data: fields, key }), text: "next", variant: "primary" }), step >= 2 && _jsx(Button, { onClick: () => goBack(), text: "back", variant: 'basic' })] }), _jsxs("div", { className: "flex flex-col space-y-[8px]", children: [_jsxs("div", { children: [_jsx("span", { className: "text-neutral-800 text-paragraph-medium-bold", children: step }), _jsxs("span", { className: "text-neutral-400 text-paragraph-medium-medium pl-[2px]", children: ["/", maxStep + 1] })] }), _jsxs("div", { className: barContainer, children: [_jsx("div", { className: `bg-primary-400 rounded-[2px] h-[4px] col-span-${step + 1}` }), _jsx("div", { className: `bg-neutral-400 rounded-[2px] h-[4px] col-span-${maxStep - step + 1}` })] })] })] })] });
                            }
                        }) }) }) }));
};
