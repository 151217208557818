import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React from 'react';
import { Select } from '../../Select';
import { useForm } from '../../Form/context';
export const SelectInput = ({ config }) => {
    const { id, label, defaultValue, options: candidate, required, debug, mRef, afterChange, belongs_to, populateOptions, multiple } = config;
    const [options, setOptions] = React.useState(!!candidate ? candidate : []);
    const { fieldsState } = useForm();
    React.useEffect(() => {
        const parentValue = fieldsState.filter((fields) => fields.find((e) => e.id === belongs_to))[0];
        if (!!parentValue)
            populateOptions(parentValue[0]).then((data) => setOptions(data));
    }, [belongs_to]);
    return (_jsx(_Fragment, { children: _jsxs("div", { children: [_jsx("label", { htmlFor: id, className: "block text-sm font-medium text-neutral-800", children: label }), _jsx(Select, { type: "input", id: id, mRef: mRef, afterUpdate: afterChange, defaultValue: defaultValue, debug: debug, options: options, multiple: multiple })] }) }));
};
