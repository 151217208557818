import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Layout, Disclosure } from '../../components';
export const QA = ({ component: Component }) => {
    const items = [
        // { title: 'Living in the edge', text: 'We live on a distributed high performance global network.'},
        // { title: 'Privacy in mind', text: 'We don’t request neither save any sensitive information.'},
        // { title: 'Green is the future', text: 'We are proudly powered by renewable energy.'},
        // { title: 'Security first', text: 'Everything is encrypted end-to-end between client and server.'},
        // { title: 'Passwordless', text: 'You can connect through your wallet or email.'}
        { title: 'Is Wally decentralised?', text: 'No, Wally isn’t decentralised but is distributed in more than 270 nodes worldwide.' },
        { title: 'Does it work with another blockchains?', text: 'No, it only works in the Stellar blockchain but in the future we are planning to offer bridges  to other networks.' },
        { title: 'Does wally store any sensitive information?', text: 'No, we don’t store secret keys or ask for any sensitive details about you.' },
        { title: 'Is Wally also a wallet?', text: 'At the moment we are only an interface to interact with the stellar blockchain. We are planning to build a wallet some time soon.' },
        { title: 'When does the private beta starts?', text: 'We are planning to launch our private beta mid December 2022.' },
        { title: 'Where can I sign to the beta?', text: 'A link will be here sometime soon.' },
        { title: 'Are you planning to launch a token ?', text: 'At the moment we are not planning to launch a token.' },
        { title: 'Can I accept any asset other than XLM?', text: 'We are working on this feature to be include in our private beta' },
        // { title: '', text: ''},
        // { title: '', text: ''},
    ];
    return (_jsx(Layout, { variant: "large", children: _jsxs("div", { className: 'mt-[40px]', children: [_jsx("div", { className: "text-h2-normal-bold text-neutral-800 mb-[20px]", children: "Fequenlty Asked Questions" }), _jsx(Disclosure, { items: items })] }) }));
};
