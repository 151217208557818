import { jsx as _jsx, Fragment as _Fragment } from "react/jsx-runtime";
import { DefaultForm, DynamicForm, StepperForm, RemixForm, FetcherForm } from './components';
import { FormProvider } from './context';
export const Form = ({ type = 'default', fields, action, debug = false, button, component: Component, transition, actionData }) => {
    const formAssert = (type, configuration) => {
        const { fields, button, action } = configuration;
        switch (type) {
            case 'stepper':
                return _jsx(StepperForm, { fields: fields, button: button, action: action, debug: debug });
            case 'fetcher':
                return !!Component && _jsx(FetcherForm, { fields: fields, button: button, action: action, debug: debug, component: Component });
            case 'remix':
                return !!Component && _jsx(RemixForm, { fields: fields, button: button, action: action, debug: debug, component: Component, transition: transition, actionData: actionData });
            case 'dynamic':
                return _jsx(DynamicForm, { fields: fields, button: button, action: action, debug: debug });
            default:
                return _jsx(DefaultForm, { fields: fields, button: button, action: action, debug: debug });
        }
    };
    return (_jsx(_Fragment, { children: _jsx(FormProvider, { fields: fields, children: formAssert(type, {
                fields,
                action,
                button
            }) }) }));
};
