import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
const incentives = [
    {
        name: 'Find and Book',
        imageSrc: 'https://tailwindui.com/img/ecommerce/icons/icon-shipping-simple.svg',
        description: "Access to an offer of online events."
    },
    {
        name: 'End-to-End Encryption',
        imageSrc: 'https://tailwindui.com/img/ecommerce/icons/icon-warranty-simple.svg',
        description: "All data in our ecosystem is encrypted for security."
    },
    {
        name: 'Join our community',
        imageSrc: 'https://tailwindui.com/img/ecommerce/icons/icon-exchange-simple.svg',
        description: "We are a community driven about passion"
    }
];
export const Incentives = () => {
    return (_jsx("div", { className: "bg-neutral-100", children: _jsx("div", { className: "max-w-7xl mx-auto py-24 sm:px-2 sm:py-32 lg:px-4", children: _jsxs("div", { className: "max-w-2xl mx-auto px-4 lg:max-w-none", children: [_jsxs("div", { className: "grid grid-cols-1 items-center gap-y-10 gap-x-16 lg:grid-cols-2", children: [_jsx("div", { className: "aspect-w-3 aspect-h-2 bg-gray-100 rounded-lg overflow-hidden", children: _jsx("img", { src: "https://tailwindui.com/img/ecommerce-images/incentives-07-hero.jpg", alt: "", className: "object-center object-cover" }) }), _jsxs("div", { children: [_jsx("h2", { className: "text-4xl font-extrabold tracking-tight text-gray-900", children: "We built our business on great customer service" }), _jsx("p", { className: "mt-4 text-gray-500", children: "At the beginning at least, but then we realized we could make a lot more money if we kinda stopped caring about that. Our new strategy is to write a bunch of things that look really good in the headlines, then clarify in the small print but hope people don't actually read it." })] })] }), _jsx("div", { className: "mt-16 grid grid-cols-1 gap-y-10 gap-x-8 lg:grid-cols-3", children: incentives.map((incentive) => (_jsxs("div", { className: "sm:flex lg:block", children: [_jsx("div", { className: "sm:flex-shrink-0", children: _jsx("img", { className: "w-16 h-16", src: incentive.imageSrc, alt: "" }) }), _jsxs("div", { className: "mt-4 sm:mt-0 sm:ml-6 lg:mt-6 lg:ml-0", children: [_jsx("h3", { className: "text-sm font-medium text-gray-900", children: incentive.name }), _jsx("p", { className: "mt-2 text-sm text-gray-500", children: incentive.description })] })] }, incentive.name))) })] }) }) }));
};
