import { jsx as _jsx } from "react/jsx-runtime";
import React from 'react';
import { useCalendar } from '@h6s/calendar';
import { format } from 'date-fns';
const monthNames = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December'
];
export const Agenda = ({ events, openModal, options, setOptions, setHeader }) => {
    const { body, today, month, navigation } = useCalendar();
    const todayRef = React.useRef(null);
    const onHover = (day) => {
        setHeader(format(day, 'MMMM'));
    };
    React.useEffect(() => {
        setHeader(monthNames[month]);
        setOptions([
            ...options,
            {
                title: 'Prev',
                type: 'button',
                action: () => navigation.toPrev()
            },
            {
                title: 'Next',
                type: 'button',
                action: () => navigation.toNext()
            }
        ]);
        todayRef.current?.scrollIntoView();
    }, [todayRef]);
    return (_jsx("div", {}));
};
{
    /* {events.map((event: any, key) => {
          const { appointments_id } = event;
          return (
            <div key={key}>
              <EventPill
                event={event}
                type={appointments_id ? 'appointment' : 'event'}
                openModal={openModal}
              />
            </div>
          );
        })} */
}
