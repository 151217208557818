import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import clsx from 'clsx';
export const PreviewCover = ({ title, type = 'default', CTA, imageUrl, }) => {
    const defaultStyle = clsx(!imageUrl && 'bg-gradient-to-r from-blue-500', 'p-[20px] h-[260px] rounded-xl drop-shadow-lg');
    const minimalStyle = clsx(!imageUrl && 'bg-gradient-to-r from-blue-500', 'p-[8px] h-[120px]');
    switch (type) {
        case 'default':
            return _jsx("div", { className: defaultStyle, style: { backgroundImage: `linear-gradient(to bottom, rgba(10, 13, 14, 0.32), rgba(249, 252, 255, 0.16)), url('${imageUrl}')`, backgroundSize: 'cover' }, children: _jsxs("div", { className: "flex flex-col justify-between h-full", children: [_jsx("div", { className: 'text-subheading-bold text-white', children: title }), _jsx("div", { children: CTA })] }) });
        case 'minimal':
            return _jsx("div", { className: minimalStyle, style: { backgroundImage: `linear-gradient(to bottom, rgba(10, 13, 14, 0.32), rgba(249, 252, 255, 0.16)), url('${imageUrl}')`, backgroundSize: 'cover' }, children: _jsxs("div", { className: "flex flex-col justify-between h-full", children: [_jsx("div", { className: 'text-subheading-bold text-white pl-[8px]', children: title }), _jsx("div", { className: "flex flex-row justify-end", children: CTA })] }) });
    }
};
