import { jsx as _jsx } from "react/jsx-runtime";
import { UploadFileInput, TextInput, TextAreaInput, SelectInput, DefaultInput, CheckBoxInput, RadioboxInput, NumberInput, PickerInput, RCInput } from './components';
import { DatePicker, TimePicker } from '..';
export const Input = ({ type = 'text', id = 'text', variant, autoComplete = 'off', label, placeholder, disabled, afterChange, populateOptions, maxLength, uploadFile, defaultValue = undefined, belongs_to, validation, icon, parentValue, options, hidden, debug = false, mRef, required, multiple }) => {
    // const oo = { data: 'YE', foo: undefined }
    // const { data = "NO", foo = "no" } = oo
    // console.log('data', data, foo)
    const config = { id, debug, autoComplete, variant, validation, defaultValue, afterChange, maxLength, populateOptions, label, disabled, icon, placeholder, hidden, mRef, uploadFile, options, required, belongs_to, parentValue, multiple };
    switch (type) {
        case 'text':
            return _jsx(TextInput, { config: { ...config, type } });
        case 'textarea':
            return _jsx(TextAreaInput, { config: { ...config, type } });
        case 'select':
            return _jsx(SelectInput, { config: { ...config, type } });
        case 'time':
            return _jsx(PickerInput, { config: { ...config, type }, component: TimePicker });
        case 'number':
            return _jsx(NumberInput, { config: { ...config, type } });
        case 'upload':
            return _jsx(UploadFileInput, { uploadFile: uploadFile, config: { ...config, type } });
        case 'datepicker':
            return _jsx(PickerInput, { config: { ...config, type }, component: DatePicker });
        case 'checkbox':
            return _jsx(CheckBoxInput, { config: { ...config, type } });
        case 'radiobox':
            return _jsx(RadioboxInput, { config: { ...config, type } });
        case 'rci':
            return _jsx(RCInput, { config: { ...config, type } });
        default:
            return _jsx(DefaultInput, { config: { ...config, type } });
    }
};
