import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Button, Layout, Person, Avatar } from '../../components';
export const ChatRoom = ({ status, cameraStatus, micStatus, screenStatus, toggleCamera, toggleMic, toggleScreenShare, mainVideo, auxVideo, data, signRelease }) => {
    const { isHost, name, description, peer, orders_id, destination } = data;
    console.log('data', data);
    console.log('!!mainVideo', !!mainVideo);
    return (_jsxs(Layout, { variant: 'full', customCss: 'h-v92', children: [_jsxs("div", { className: "flex flex-row space-x-[20px] pt-[40px]", children: [_jsx("div", { className: "w-[1080px]", children: status === 'ONLINE' ? mainVideo :
                            _jsx("div", { className: "flex flex-row justify-center items-center h-full w-full aspect-video bg-neutral-50 border border-neutral-150 rounded-[20px]", children: _jsx("div", { children: _jsx(Avatar, { imageUrl: peer.avatar_url, customCss: "animate-pulse" }) }) }) }), _jsxs("div", { className: "flex flex-col justify-between w-[436px]", children: [isHost ?
                                _jsxs("div", { className: "flex flex-col space-y-[8px]", children: [_jsx("div", { className: "text-subheading-medium text-neutral-800", children: "Participant" }), _jsx(Person, { fullname: `${peer.first_name} ${peer.last_name}`, imageUrl: peer.avatar_url })] })
                                :
                                    _jsxs("div", { className: "flex flex-col space-y-[8px] h-full", children: [_jsx("div", { className: "text-subheading-medium text-neutral-800", children: name }), _jsx("div", { className: "text-paragraph-medium-bold text-neutral-600", children: "hosted by" }), _jsx("div", { children: _jsx(Person, { fullname: `${peer.first_name} ${peer.last_name}`, imageUrl: peer.avatar_url }) }), _jsx("div", { className: "text-paragrap-small-medium text-neutral-800", children: description }), _jsx("div", { className: "grow", children: _jsx("div", { className: "flex flex-col justify-end h-full", children: _jsxs("div", { className: "flex flex-col space-y-[8px] mb-[20px]", children: [_jsx("div", { className: "text-caption-medium text-neutral-800", children: "Actions" }), _jsx("div", { children: _jsx(Button, { text: "Release", size: "small", variant: "outline", onClick: () => signRelease({ orders_id, destination }) }) })] }) }) })] }), _jsx("div", { children: auxVideo })] })] }), _jsx("div", { className: "w-full mt-[40px]", children: _jsxs("div", { className: "flex space-x-[32px] mx-auto w-fit bg-neutral-50 border border-neutral-800 rounded-[24px] py-[20px] px-[40px]", children: [_jsx(Button, { icon: cameraStatus, size: "large", variant: 'basic', onClick: () => toggleCamera(), active: cameraStatus === 'cameraOn' ? true : false }), _jsx(Button, { icon: micStatus, size: "large", variant: 'basic', onClick: () => toggleMic(), active: micStatus === 'microphoneOn' ? true : false }), _jsx(Button, { icon: screenStatus, size: "large", variant: 'basic', onClick: () => toggleScreenShare(), active: screenStatus === 'shareOn' ? true : false }), _jsx(Button, { icon: "phoneOff", size: "large", variant: 'secondary', customCss: 'text-white' })] }) })] }));
};
