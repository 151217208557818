import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Button, SummaryItem, Layout } from '../../components';
export const CheckoutSummary = ({ onClick, order, afterCopy }) => {
    console.log('order', order);
    const copyToClipboard = (text) => {
        navigator.clipboard.writeText(text).then(function () {
            // console.log('Async: Copying to clipboard was successful!');
            afterCopy();
        }, function (err) {
            // console.error('Async: Could not copy text: ', err);
        });
    };
    return (_jsx(Layout, { children: _jsxs("div", { className: "w-[512px] mx-auto p-[20px] space-y-[16px]", children: [_jsx("div", { className: "text-center text-caption-medium text-neutral-600", children: new Date().toString() }), _jsxs("div", { children: [_jsx("div", { className: "mx-auto w-fit bg-neutral-50 text-neutral-700 -mb-[10px] z-10 relative px-[8px] text-paragraph-medium-bold", children: "Smart Contract" }), _jsxs("div", { className: "flex border-2 space-x-[8px] text-center border-dashed border-neutral-600 text-neutral-800 p-[8px] relative rounded-xl", children: [_jsx("div", { className: "grow text-subheading-bold text-paragraph-large-bold truncate", children: order.xdr }), _jsx("div", { children: _jsx(Button, { text: "copy", variant: "outline", size: "tiny", onClick: () => copyToClipboard(order.xdr) }) })] })] }), _jsx(SummaryItem, { label: "Contract Type", value: "XDR" }), _jsx("div", { children: _jsx("ul", { role: "list", className: "divide-y", children: _jsxs("li", { className: "flex flex-col justify-between space-y-[6px]", children: [_jsx(SummaryItem, { label: "Event Type", value: "Experience", variant: "border-top" }), _jsx(SummaryItem, { label: "Name", value: order.name }), _jsx(SummaryItem, { label: "Host", value: `${order.host.first_name} ${order.host.last_name}` }), _jsx(SummaryItem, { label: "Location", value: "Remote" }), _jsx(SummaryItem, { label: "Destination", value: order.destination }), _jsx(SummaryItem, { label: "Duration", value: `${order.time.minutes} minutes` }), _jsx(SummaryItem, { label: "Price", value: order.price })] }, order.id) }) }), _jsxs("div", { className: "text-sm mt-10 space-y-6", children: [_jsx(SummaryItem, { label: "Network fee", value: order.network_fee }), _jsx(SummaryItem, { label: "Contract fee", value: "0.00000" }), _jsx(SummaryItem, { label: "Total", value: order.price, variant: "border-top" })] }), _jsx(Button, { text: "Pay", icon: "stellar", customCss: "w-full", onClick: () => onClick() })] }) }));
};
