import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React from 'react';
import { Button, Input } from '../../index';
export const DefaultForm = ({ fields, action, debug = false, button, }) => {
    const getFields = fields.map((item) => {
        const isArr = Object.prototype.toString.call(item) == '[object Array]';
        if (isArr) {
            const output = item.map(({ field }) => field);
            return output;
        }
        else {
            const { field } = item;
            return [{ ...field }];
        }
    });
    return (_jsx(_Fragment, { children: _jsxs("form", { method: "post", action: !!action ? action : '', children: [_jsx("div", { className: "space-y-4", children: getFields.map((fields, key) => {
                        return fields.map((field) => {
                            const { type, id, label, variant, placeholder, autoComplete, validation, required, icon, maxLength, uploadFile, options, hidden = false, disabled = false, defaultValue } = field;
                            let mRef = undefined;
                            if (field.type === 'text') {
                                mRef = React.createRef();
                            }
                            else if (field.type === 'textarea') {
                                mRef = React.createRef();
                            }
                            else if (field.type === 'select') {
                                mRef = React.createRef();
                            }
                            else {
                                mRef = React.createRef();
                            }
                            return (_jsx("div", { children: _jsx(Input, { type: type, variant: variant, id: id, disabled: disabled, options: options, validation: validation, label: label, icon: icon, maxLength: maxLength, mRef: mRef, uploadFile: uploadFile, hidden: hidden, debug: debug, defaultValue: defaultValue, autoComplete: autoComplete, placeholder: placeholder, required: required }, key) }, key));
                        });
                    }) }), _jsx("div", { className: "pt-6", children: _jsx(Button, { text: button.text, type: "submit", rounded: "medium", variant: button.variant, customCss: button.customCss }) })] }) }));
};
