import { Fragment as _Fragment, jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import { Button, Input } from '../../index';
export const DynamicForm = ({ fields, action, debug = false, button, }) => {
    const [fieldsState, updateFields] = React.useState(fields.map(({ field }) => { return { id: field.id, value: null }; }));
    return (_jsx(_Fragment, { children: _jsxs("form", { method: "post", action: !!action ? action : '', children: [_jsx("div", { className: "space-y-4", children: fields.map(({ field }, key) => {
                        const { type, id, label, placeholder, autoComplete, required, uploadFile, icon, options: ops, hidden = false, afterChange, populateOptions, belongs_to, disabled = false, defaultValue } = field;
                        const updateField = (e) => {
                            const arr = [...fieldsState], index = arr.findIndex((e) => id === e.id);
                            arr[index].value = e.id;
                            console.log('arrr', arr);
                            updateFields([...arr]);
                            // afterChange()
                        };
                        let options, parentValue;
                        if (!!belongs_to) {
                            const index = fieldsState.findIndex((e) => belongs_to === e.id);
                            parentValue = fieldsState[index].value;
                            options = populateOptions(fieldsState[index]);
                        }
                        if (!!ops)
                            options = ops;
                        if (!options && type === 'select')
                            return _jsx(_Fragment, {});
                        if (!options && type === 'radiobox')
                            return _jsx(_Fragment, {});
                        return (_jsx("div", { children: _jsx(Input, { type: type, id: id, disabled: disabled, options: options, label: label, uploadFile: uploadFile, hidden: hidden, icon: icon, parentValue: parentValue, belongs_to: belongs_to, afterChange: updateField, populateOptions: populateOptions, debug: debug, defaultValue: !!defaultValue ? defaultValue : '', autoComplete: autoComplete, placeholder: placeholder, required: required }, key) }, key));
                    }) }), _jsx("div", { className: "pt-6", children: _jsx(Button, { text: button.text, type: "submit", rounded: "medium", size: button.size, icon: button.icon, loading: button.loading, variant: button.variant, customCss: button.customCss }) })] }) }));
};
