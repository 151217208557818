import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Cover, Person, Layout, ExperienceCard } from '../../components/index';
export const PersonProfile = ({ profile, component: Component, onClick }) => {
    let { experiences, host } = profile;
    console.log(experiences);
    const { avatar_url, about, cover_url, first_name, last_name, pagename } = host;
    return (_jsxs(Layout, { variant: "large", customCss: 'mb-[40px]', children: [_jsx(Cover, { imageUrl: cover_url }), _jsx(Layout, { children: _jsxs("div", { className: "p-[8px] h-fit flex flex-col space-y-[20px]", style: { marginTop: '-64px' }, children: [_jsx(Person, { imageUrl: avatar_url, fullname: `${first_name} ${last_name}`, details: `@${pagename}`, variant: "lab" }), _jsxs("div", { children: [_jsx("div", { className: "text-subheading-bold text-neutral-800", children: "About" }), _jsx("p", { className: "text-paragraph-small-medium text-neutral-600", children: about })] }), experiences &&
                            _jsxs("div", { children: [_jsx("div", { className: "text-subheading-bold text-neutral-800 pb-[16px]", children: "Experiences" }), _jsx("div", { className: "flex flex-row space-x-[12px]", children: experiences.reverse().slice(7).map((experience, key) => {
                                            return (_jsx(ExperienceCard, { host: host, path: `/${host.pagename}/${experience.id}`, experience: experience, component: Component }, key));
                                        }) })] })] }) })] }));
};
