import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React from 'react';
import { RadioGroup } from '@headlessui/react';
import { Cover, Layout, Button, GroupItems, Person } from '../../components';
import { RatingCollection } from '../../collections';
import clsx from 'clsx';
import { DateTime } from 'luxon';
export const ExperienceProfile = ({ openModal, experience, host, component: Component }) => {
    console.log('experience', experience);
    const { avatar_url, first_name, last_name } = host;
    const { cover_url, name, description, events, price, duration, time, public_key, languages, reviews } = experience;
    const [event, setEvent] = React.useState(events[0]);
    return (_jsx(_Fragment, { children: _jsxs(Layout, { variant: "large", children: [_jsx(Cover, { imageUrl: cover_url }), _jsxs("div", { className: "flex space-x-[20px] px-[20px] lg:px-[40px]", children: [_jsxs("div", { className: "flex-auto p-[20px] space-y-[20px]", children: [_jsxs("div", { children: [_jsx("div", { className: "text-neutral-800 text-subheading-bold pb-[8px]", children: name }), _jsxs("div", { className: "text-neutral-600 text-paragraph-small-medium", children: [description, " ", languages] })] }), _jsx(GroupItems, { items: [['cash', `${price} XLM`], ['clock', `${time.minutes} minutes`]] })] }), _jsxs("div", { className: "flex flex-col space-y-[8px] bg-neutral-50 py-[32px] px-[32px] rounded-[20px] border border-neutral-100", style: { marginTop: '-64px' }, children: [_jsxs("div", { className: "flex flex-col space-y-[8px]", children: [_jsx("div", { className: "text-neutral-800 text-paragraph-medium-bold", children: "Hosted by" }), _jsx(Person, { imageUrl: avatar_url, fullname: `${first_name} ${last_name}`, to: host.pagename, component: Component })] }), _jsxs("div", { className: "flex flex-col space-y-[20px]", children: [_jsxs(RadioGroup, { value: event, onChange: setEvent, children: [_jsx(RadioGroup.Label, { className: "text-neutral-700 text-paragraph-medium-bold", children: "Events" }), _jsx("div", { className: "flex flex-col space-y-[8px] justify-between overflow-hidden overflow-y-scroll mt-[20px] pb-[8px]", children: events.slice(0, 5).map((event) => {
                                                        const dt = DateTime.fromSeconds(event.timestamp);
                                                        return (_jsx(RadioGroup.Option, { as: "div", value: event, className: ({ active, checked }) => clsx('m-[2px] border border-gray-300 hover:border-secondary-600 rounded-xl p-[8px] cursor-pointer focus:outline-none w-[207px] flex flex-row justify-between', active && 'border-secondary-600 ring-[2px] ring-offset-[1px] ring-offset-secondary-100 ring-secondary-400', checked && 'border-secondary-600 ring-[2px] ring-offset-[1px] ring-offset-secondary-100 ring-secondary-400'), children: ({ active, checked }) => (_jsxs(_Fragment, { children: [_jsx(RadioGroup.Label, { className: clsx(checked ? 'text-secondary-400' : 'text-neutral-700'), children: _jsxs("div", { className: "text-paragraph-small-bold", children: [dt.weekdayShort, " ", dt.day, "/", dt.month] }) }), _jsxs(RadioGroup.Description, { as: "div", className: clsx("text-caption-medium w-[59.55px]", checked ? 'text-secondary-400' : 'text-neutral-700'), children: [('0' + dt.hour).slice(-2), ":", ('0' + dt.minute).slice(-2), " hs"] })] })) }, event.date));
                                                    }) })] }), _jsx(Button, { text: "Book", onClick: () => openModal({ type: 'order_preview', content: { ...event, price, public_key, host }, padding: 'large' }), customCss: "w-full" })] })] })] }), _jsx("div", { className: "px-[20px] lg:px-[40px]", children: _jsx("div", { className: "p-[20px]", children: _jsx(RatingCollection, { reviews: reviews }) }) })] }) }));
};
