import { jsx as _jsx } from "react/jsx-runtime";
import React from 'react';
export const FormContext = React.createContext({});
export const FormProvider = ({ children, fields }) => {
    const getFields = fields.map((item) => {
        const isArr = Object.prototype.toString.call(item) == '[object Array]';
        if (isArr) {
            const output = item.map(({ field }) => field);
            return output;
        }
        else {
            const { field } = item;
            return [{ ...field }];
        }
    });
    const [fieldsState, updateFields] = React.useState(getFields);
    const updateForm = (e) => {
        updateFields(e);
    };
    return (_jsx(FormContext.Provider, { value: { fieldsState, updateForm }, children: children }));
};
export const useForm = () => {
    return React.useContext(FormContext);
};
