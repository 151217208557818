import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { Layout, Logo, Button, Popover } from '../../components/index';
export const Header = ({ component: Component, menu: Menu, communityMenu: CommunityMenu, userNavigation, updateTheme, session = false, connect, openSearch }) => {
    // Move to Context
    // const datos = ['Dato 1', 'Dato 2', 'Dato 3', 'Dato 4']
    // const [ tip, setTip] = React.useState({ data: datos[0], key: 0});
    // const shuffle = () => {
    //   const index = tip.key === (datos.length - 1) ? 0 : tip.key + 1
    //   setTip({ data: datos[index], key: index});
    // }
    // React.useEffect(() => {
    //   const intervalId = setInterval(shuffle, 2000)
    //   return () => clearInterval(intervalId); //This is important
    // }, [shuffle])
    return (_jsx(Layout, { variant: 'large', children: _jsx("div", { className: "h-v8 pt-[8px] relative z-[10]", children: _jsxs("div", { className: "flex justify-between items-center py-[4px] bg-neutral-50 border-0 lg:border-2 border-neutral-200 rounded-full sm:px-6 lg:px-8 md:justify-start md:space-x-10", children: [_jsx("div", { className: "flex justify-start lg:w-0 lg:flex-1", children: _jsx(Component, { to: "/", children: _jsx(Logo, { color: "neutral-800" }) }) }), _jsx("div", { className: "md:flex items-center justify-end md:flex-1 lg:w-0", children: _jsxs("div", { className: "flex flex-row space-x-[20px]", children: [_jsx(Popover, { button: {
                                        size: 'small',
                                        name: 'Community',
                                        variant: 'basic'
                                    }, children: CommunityMenu }), _jsx("div", { className: "text-tertiary-400 flex items-center rounded-[8px] border border-tertiary-300 px-[6px] py-[3px]", children: "TESTNET" }), session ? (_jsx(_Fragment, { children: _jsx(Popover, { button: {
                                            icon: 'bars',
                                            size: 'tiny',
                                            rounded: 'small',
                                            variant: 'outline'
                                        }, children: Menu }) })) : (_jsx(_Fragment, { children: _jsx(Button, { text: "Connect", variant: "primary", size: "small", onClick: () => connect(), customCss: "ml-4" }) }))] }) })] }) }) }));
};
