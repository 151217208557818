import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Button, Input } from '../../index';
export const RemixForm = ({ fields, action, debug = false, button, component: Form, transition, actionData }) => {
    console.log('Transition', transition);
    console.log('ActionData', actionData);
    return (_jsx(_Fragment, { children: _jsxs(Form, { method: "post", action: !!action ? action : '', children: [_jsx("div", { className: "space-y-4", children: fields.map(({ field }, key) => {
                        const { type, id, label, variant, placeholder, autoComplete, required, icon, uploadFile, options, hidden = false, disabled = false, defaultValue } = field;
                        return (_jsx("div", { children: _jsx(Input, { type: type, variant: variant, id: id, options: options, label: label, icon: icon, disabled: disabled, uploadFile: uploadFile, hidden: hidden, debug: debug, defaultValue: !!defaultValue ? defaultValue : '', autoComplete: autoComplete, placeholder: placeholder, required: required }, key) }, key));
                    }) }), _jsx("div", { className: "pt-6", children: _jsx(Button, { text: button.text, type: "submit", rounded: "medium", variant: button.variant, customCss: button.customCss }) })] }) }));
};
