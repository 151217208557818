import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Button, Layout, Table } from '../../components';
import { DateTime } from 'luxon';
export const BookingCollection = ({ bookings, component: Component }) => {
    const data = bookings.map(({ status, user, timestamp, experience }) => {
        const dt = DateTime.fromSeconds(timestamp);
        // <div className="text-paragraph-small-bold">{dt.weekdayShort} {dt.day}/{dt.month}</div>
        return [{ value: experience.name, type: 'simple' }, { value: `${dt.weekdayShort} ${dt.day}/${dt.month} ${('0' + dt.hour).slice(-2)}:${('0' + dt.minute).slice(-2)}`, type: 'simple' }, { value: status, type: 'simple' }, { value: user.first_name, type: 'simple' }];
    });
    const header = ['', '', '', ''];
    const hasActive = bookings.filter((a) => a.event.status === 'ACTIVE')[0];
    return (_jsx(Layout, { customCss: "py-[40px]", variant: "large", children: _jsxs("div", { className: "p-[20px] bg-gradient-to-b from-neutral-50 to-neutral-100 rounded-xl drop-shadow-lg", children: [_jsx("div", { className: 'flex justify-between mb-[20px]', children: _jsx("div", { className: "text-[24.2px] text-neutral-800 font-bold", children: "Bookings" }) }), _jsx("div", { children: !!hasActive &&
                        _jsx("div", { className: "flex items-center py-[8px]", children: _jsxs("div", { className: "relative", children: [_jsxs("div", { className: "flex flex-col space-y-[8px] bg-neutral-150 px-[30px] py-[15px] rounded-[8px] shadow-2xl w-full", children: [_jsx("div", { className: "text-paragraph-medium-bold text-neutral-800", children: hasActive.event.experience.name }), _jsx(Button, { component: Component, to: `/rooms/${hasActive.event.rooms_id}`, text: "Enter Room", variant: 'secondary', customCss: 'w-full', size: 'tiny', type: "link" })] }), _jsx("div", {}), _jsx("div", { className: "absolute top-0 right-0 -mr-1 -mt-1 w-[36px] h-[18px] rounded-[4px] bg-green-300 animate-ping" }), _jsx("div", { className: "absolute top-0 right-0 -mr-1 -mt-1 w-[36px] h-[18px] rounded-[4px] bg-green-300 text-caption-bold text-neutral-800 text-center tracking-wide", children: "LIVE" })] }) }) }), _jsxs("div", { children: [_jsx("div", { className: "text-subheading-medium mb-[8px] text-neutral-800", children: "History" }), _jsx(Table, { header: header, data: data, component: Component })] })] }) }));
};
