import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Layout, DateTime } from '../../components';
import clsx from 'clsx';
export const DashboardMenu = ({ component: Component, userNavigation, }) => {
    // Move to Context
    // const datos = ['Dato 1', 'Dato 2', 'Dato 3', 'Dato 4']
    // const [ tip, setTip] = React.useState({ data: datos[0], key: 0});
    // const shuffle = () => {
    //   const index = tip.key === (datos.length - 1) ? 0 : tip.key + 1
    //   setTip({ data: datos[index], key: index});
    // }
    // React.useEffect(() => {
    //   const intervalId = setInterval(shuffle, 2000)
    //   return () => clearInterval(intervalId); //This is important
    // }, [shuffle])
    const tabStyle = clsx('grow text-neutral-800 p-[8px] border-neutral-200 border rounded-[8px]');
    const buttonStyle = clsx('text-neutral-800 py-[8px] pl-[16px] pr-[48px] border-neutral-200 border rounded-[8px]');
    return (_jsx(Layout, { variant: 'large', customCss: 'my-4', children: _jsxs("div", { className: "flex flex-row space-x-[8px]", children: [_jsx(DateTime, { variant: "medium", component: Component, to: "/agenda" }), _jsx(Component, { className: tabStyle, to: "/bookings", children: "Earnings" }), _jsx(Component, { className: tabStyle, to: "/experiences", children: "Experiences" }), _jsx(Component, { className: buttonStyle, to: "/withdraw", children: "Withdraw" })] }) }));
};
