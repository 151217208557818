import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import clsx from 'clsx';
import { Button } from '..';
export const Mission = ({ sequence, customCss = '' }) => {
    const [step, setStep] = React.useState({ node: sequence[0].node, type: sequence[0].type, key: 0 });
    const maxStep = sequence.length - 1;
    const style = clsx(
    // variant === 'small' && 'max-w-screen-sm',
    `flex flex-col space-y-[20px] ${customCss}`);
    const nextStep = (step, maxStep) => {
        const n = step.key + 1;
        if (n <= maxStep) {
            // const newArr = [...fieldsState]
            // switch (type) {
            //   case 'text':
            //     newArr[key] = { id, defaultValue: textInputRef?.current?.value, type }
            //     break;
            //   case 'textarea':
            //     newArr[key] = { id, defaultValue: textAreaRef?.current?.value, type }
            //     break;
            //   default:
            //     newArr[key] = { id, defaultValue: textInputRef?.current?.value, type }
            // }
            // updateFields([...newArr])
            setStep({ node: sequence[n].node, type: sequence[n].type, key: n });
        }
    };
    return (_jsxs("div", { className: style, children: [step.node, _jsx(Button, { text: 'Next', onClick: () => nextStep(step, maxStep) })] }));
};
