import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import * as React from 'react';
import clsx from 'clsx';
import { CheckIcon, XIcon } from '@heroicons/react/solid';
import { Icon } from '../..';
export const TextInput = ({ config }) => {
    const { id, label, autoComplete, hidden, icon, placeholder, afterChange, validation, defaultValue, maxLength, disabled, required, mRef } = config;
    const [status, setStatus] = React.useState('EMPTY');
    // const updateStatus = async (value: 'VALID' | 'ERROR') => {
    //   setStatus(value)
    // }
    const validate = async (event) => {
        const e = await validation?.fn(event);
        if (e === true) {
            setStatus('VALID');
            afterChange(id);
        }
        else if (e === false) {
            setStatus('ERROR');
        }
    };
    const inputStyle = clsx(`shadow-sm focus:ring-blue-500 focus:border-blue-500 block w-full sm:text-sm border-neutral-300 rounded-md text-neutral-800 bg-neutral-100`, status === 'VALID' && 'border-green-400 focus:ring-green-500 focus:border-green-500', status === 'ERROR' && 'border-red-400 focus:ring-red-500 focus:border-red-500', !!icon && 'pl-10');
    const iconStyle = clsx('absolute inset-y-0 right-0 pr-3 flex items-center text-neutral-400', status === 'VALID' && 'text-green-400', status === 'ERROR' && 'text-red-400');
    return (_jsx(_Fragment, { children: _jsxs("div", { children: [_jsx("label", { htmlFor: id, className: !hidden ? "block text-sm font-medium text-neutral-800" : 'hidden', children: label }), _jsxs("div", { className: "mt-1 relative", children: [_jsx("input", { disabled: disabled, type: !hidden ? 'text' : 'hidden', name: id, id: id, ref: mRef, readOnly: hidden, maxLength: maxLength, autoComplete: autoComplete, onChange: (e) => validate(e.target.value), className: inputStyle, placeholder: placeholder, defaultValue: defaultValue, required: required }), !!icon &&
                            _jsx("div", { className: "absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none text-neutral-800", children: _jsx(Icon, { name: icon }) }), status !== 'EMPTY' &&
                            _jsxs("div", { className: iconStyle, children: [status === 'VALID' && _jsx(CheckIcon, { className: "w-[20px] h-[20px]" }), status === 'ERROR' && _jsx(XIcon, { className: "w-[20px] h-[20px]" })] })] })] }) }));
};
