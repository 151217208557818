import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Button, Table } from '../../components';
import { DateTime } from 'luxon';
export const AppointmentCollection = ({ appointments, component: Component, rateEvent, signRelease }) => {
    const header = ['name', 'host', 'status', 'date', 'starts in', ''];
    // const datetime: any = DateTime.fromHTTP('Tue, 13 Sep 2022 16:15:00 GMT').diffNow('minutes')
    // console.log('datetime', datetime.values.minutes)
    const data = appointments.map(({ id, event, host, isReviewable, orders_id }) => {
        const dt = DateTime.fromHTTP(event.date);
        const inMinutes = Math.round(Number(dt.diffNow('minutes').toFormat('m'))), starts_in = inMinutes > 0 ? `${inMinutes}  min.` : '-';
        const rate = isReviewable ? { value: { text: 'Rate', onClick: rateEvent, content: { id, event, host } }, type: 'button' } : { value: '', type: 'simple' };
        const release = true ? { value: { text: 'Release', onClick: signRelease, content: { orders_id } }, type: 'button' } : { value: '', type: 'simple' };
        return [
            { value: { text: event?.experience?.name, imageUrl: event?.experience?.cover_url }, type: 'image' },
            { value: { first_name: host.first_name, avatar_url: host.avatar_url }, type: 'person' },
            { value: event.status, type: 'simple' },
            { value: `${dt.weekdayShort} ${dt.day}/${dt.month} ${('0' + dt.hour).slice(-2)}:${('0' + dt.minute).slice(-2)}`, type: 'simple' },
            { value: starts_in, type: 'simple' },
            rate,
            release
        ];
    });
    const hasActive = appointments.filter((a) => a.event.status === 'ACTIVE')[0];
    return (_jsxs("div", { className: "p-[20px] bg-gradient-to-b from-neutral-50 to-neutral-100 rounded-xl drop-shadow-lg", children: [_jsx("div", { className: 'flex justify-between mb-[20px]', children: _jsx("div", { className: "text-[24.2px] text-neutral-800 font-bold", children: "Appointments" }) }), !!hasActive &&
                _jsxs("div", { children: [_jsx("div", { className: "text-subheading-medium mb-[8px] text-neutral-800", children: "Now" }), _jsx("div", { className: "flex items-center py-[8px]", children: _jsxs("div", { className: "relative", children: [_jsxs("div", { className: "flex flex-col space-y-[8px] bg-neutral-150 px-[30px] py-[15px] rounded-[8px] shadow-2xl w-full", children: [_jsx("div", { className: "text-paragraph-medium-bold text-neutral-800", children: hasActive.event.experience.name }), _jsx(Button, { component: Component, to: `/rooms/${hasActive.event.rooms_id}`, text: "Enter Room", variant: 'secondary', customCss: 'w-full', size: 'tiny', type: "link" })] }), _jsx("div", {}), _jsx("div", { className: "absolute top-0 right-0 -mr-1 -mt-[4px] w-[36px] h-[18px] rounded-[4px] bg-green-300 animate-ping" }), _jsx("div", { className: "absolute top-0 right-0 -mr-1 -mt-[4px] w-[36px] h-[18px] rounded-[4px] bg-green-300 text-caption-bold text-neutral-800 text-center tracking-wide", children: "LIVE" })] }) })] }), _jsxs("div", { children: [_jsx("div", { className: "text-subheading-medium mb-[8px] text-neutral-800", children: "History" }), _jsx(Table, { header: header, data: data, component: Component, customCss: "pt-[8px]" })] })] }));
};
